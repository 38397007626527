exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-interactive-logo-index-tsx": () => import("./../../../src/pages/interactive-logo/index.tsx" /* webpackChunkName: "component---src-pages-interactive-logo-index-tsx" */),
  "component---src-templates-event-page-tsx": () => import("./../../../src/templates/event-page.tsx" /* webpackChunkName: "component---src-templates-event-page-tsx" */),
  "component---src-templates-news-page-tsx": () => import("./../../../src/templates/news-page.tsx" /* webpackChunkName: "component---src-templates-news-page-tsx" */),
  "component---src-templates-paginated-news-list-tsx": () => import("./../../../src/templates/paginated-news-list.tsx" /* webpackChunkName: "component---src-templates-paginated-news-list-tsx" */),
  "component---src-templates-static-page-tsx": () => import("./../../../src/templates/static-page.tsx" /* webpackChunkName: "component---src-templates-static-page-tsx" */)
}

